<template>
  <v-card class="flexcard" max-width="400" height="100%" color="grey lighten-3">
    <v-toolbar dark color="primary" dense>
      <v-toolbar-title>ปิดรับ .... เวลา ....</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-title>
      DATATABLE
    </v-card-title>
    <v-card-text class="keyboard">
      <div><v-btn class="btn" color="red" dark>อื่นๆ</v-btn></div>
      <div><v-btn class="btn" dark>บน</v-btn></div>
      <div><v-btn class="btn" dark>ล่าง</v-btn></div>
      <div class="del">
        <v-btn class="btn" color="indigo darken-3" dark>
          <v-icon large>
            mdi-arrow-left-bold
          </v-icon></v-btn
        >
      </div>
      <div><v-btn class="btn_number" dark>7</v-btn></div>
      <div><v-btn class="btn_number" dark>8</v-btn></div>
      <div><v-btn class="btn_number" dark>9</v-btn></div>
      <div class="multiply">
        <v-btn class="btn_2" color="amber darken-2" dark>
          <v-icon>
            mdi-close
          </v-icon></v-btn
        >
      </div>
      <div><v-btn class="btn_number" dark>4</v-btn></div>
      <div><v-btn class="btn_number" dark>5</v-btn></div>
      <div><v-btn class="btn_number" dark>6</v-btn></div>
      <div><v-btn class="btn_number" dark>1</v-btn></div>
      <div><v-btn class="btn_number" dark>2</v-btn></div>
      <div><v-btn class="btn_number" dark>3</v-btn></div>
      <div class="zero"><v-btn class="btn_number" dark>0</v-btn></div>
      <div><v-btn class="btn" color="light-blue darken-4" dark>ก.</v-btn></div>
      <div class="equal"><v-btn class="btn_2"  color="red darken-1" dark>=</v-btn></div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "KEY_PAD",

  components: {},
  watch: {},
  methods: {},
  data: () => ({
    dialog: false,
  }),
};
</script>

<style scoped>
.keyboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  height: 40%;
  font-weight: bold;
}

.zero {
  grid-column: 1/3;
}

.multiply {
  grid-row-start: 2;
  grid-row-end: 4;
  grid-column-start: 4;
  grid-column-end: 5;
}

.equal {
  grid-row-start: 4;
  grid-row-end: 6;
  grid-column-start: 4;
  grid-column-end: 5;
}
/* .keyboard .v-btn {
  background-color: darkslateblue !important;
  height: 96% !important;
  width: 99% !important;
} */

/* .keyboard > div {
  text-align: center;
  font-size: 30px;
} */

.btn {
  height: 96% !important;
  width: 99% !important;
}

.btn_2 {
  height: 98% !important;
  width: 99% !important;
}

.btn_number {
  background-color: darkcyan !important;
  height: 96% !important;
  width: 99% !important;
}
</style>
